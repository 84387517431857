import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"

// Home
import HomeStartupPage from "./Pages/Portfolios/PortfolioClassic/PortfolioHabsoe";


// Pages
const LandingPage = lazy(() => import("./Pages/Landing"))
const PortfolioPage = lazy(() => import("./Pages/Portfolios"))
const PortfolioHabsoePage = lazy(() => import("./Pages/Portfolios/PortfolioClassic/PortfolioHabsoe"))
const PortfolioSliderPage = lazy(() => import("./Pages/Portfolios/PortfolioSlider"))


// Header
const TransparentHeaderPage = lazy(() => import("./Pages/Header/TransparentHeaderPage"))
const WhiteHeaderPage = lazy(() => import("./Pages/Header/WhiteHeaderPage"))
const DarkHeaderPage = lazy(() => import("./Pages/Header/DarkHeaderPage"))
const HeaderwithTopbarPage = lazy(() => import("./Pages/Header/HeaderwithTopbarPage"))
const HeaderWithPushPage = lazy(() => import("./Pages/Header/HeaderWithPushPage"))
const CenterNavigationPage = lazy(() => import("./Pages/Header/CenterNavigationPage"))
const CenterLogoPage = lazy(() => import("./Pages/Header/CenterLogoPage"))
const TopLogoPage = lazy(() => import("./Pages/Header/TopLogoPage"))
const OnePageNavigationPage = lazy(() => import("./Pages/Header/OnePageNavigationPage"))
const LeftMenuClassicPage = lazy(() => import("./Pages/Header/LeftMenuClassicPage"))
const LeftMenuModernPage = lazy(() => import("./Pages/Header/LeftMenuModernPage"))
const HeaderAlwaysFixedPage = lazy(() => import("./Pages/Header/HeaderTypes/HeaderAlwaysFixedPage"))
const HeaderResponsiveSticky = lazy(() => import("./Pages/Header/HeaderTypes/HeaderResponsiveSticky"))
const HeaderDisableFixed = lazy(() => import("./Pages/Header/HeaderTypes/HeaderDisableFixed"))
const HeaderReverseScroll = lazy(() => import("./Pages/Header/HeaderTypes/HeaderReverseScroll"))
const MobileMenuClassicPage = lazy(() => import("./Pages/Header/MobileMenu/MobileMenuClassicPage"))
const MobileMenuModernPage = lazy(() => import("./Pages/Header/MobileMenu/MobileMenuModernPage"))
const MobileMenuFullScreen = lazy(() => import("./Pages/Header/MobileMenu/MobileMenuFullScreen"))
const HamburgerMenuPage = lazy(() => import("./Pages/Header/HamburgerMenu/HamburgerMenuPage"))
const HamburgerMenuModernPage = lazy(() => import("./Pages/Header/HamburgerMenu/HamburgerMenuModernPage"))
const HamburgerMenuHalfPage = lazy(() => import("./Pages/Header/HamburgerMenu/HamburgerMenuHalfPage"))


// Footer
const FooterStyle01Page = lazy(() => import("./Pages/Footer/FooterStyle01Page"))
const Footer = lazy(() => import("./Pages/Footer"))
const Privacy = lazy(() => import("./Pages/Privacy"))

// Model-Popup
// Icon Packs
// Page-Title
// About Pages
// Services Pages
// Contact Pages

// Additional Pages
const SearchResultPage = lazy(() => import("./Pages/AdditionalPages/SearchResultPage"))

// Shop Pages
// Blogs
// Blogs Types

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence mode='wait'>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />

                  {/* Headers */}
                  <Route path="headers">
                    <Route path="transparent-header" element={<TransparentHeaderPage style={{ "--base-color": "#0038e3" }} />} />
                    <Route path="white-header" element={<WhiteHeaderPage />} />
                    <Route path="dark-header" element={<DarkHeaderPage />} />
                    <Route path="header-with-top-bar" element={<HeaderwithTopbarPage style={{ "--base-color": "#828282" }} />} />
                    <Route path="header-with-push" element={<HeaderWithPushPage style={{ "--base-color": "#828282" }} />} />
                    <Route path="center-navigation" element={<CenterNavigationPage />} /> <Route path="center-logo" element={<CenterLogoPage />} />
                    <Route path="top-logo" element={<TopLogoPage />} />
                    <Route path="one-page-navigation" element={<OnePageNavigationPage style={{ "--base-color": "#f4d956" }} />} />
                    <Route path="header-always-fixed" element={<HeaderAlwaysFixedPage />} /> <Route path="header-disable-fixed" element={<HeaderDisableFixed />} />
                    <Route path="header-reverse-scroll" element={<HeaderReverseScroll />} /> <Route path="header-responsive-sticky" element={<HeaderResponsiveSticky
                      style={{ "--base-color": "#fff" }} />} />
                    <Route path="left-menu-classic" element={<LeftMenuClassicPage />} /> <Route path="left-menu-modern" element={<LeftMenuModernPage
                      style={{ "--base-color": "#c7da26" }} />} />
                    <Route path="mobile-menu-classic" element={<MobileMenuClassicPage />} /> <Route path="mobile-menu-modern" element={<MobileMenuModernPage />} />
                    <Route path="mobile-menu-full-screen" element={<MobileMenuFullScreen />} /> <Route path="hamburger-menu" element={<HamburgerMenuPage />} />
                    <Route path="hamburger-menu-modern" element={<HamburgerMenuModernPage style={{ "--base-color": "#fff" }} />} />
                    <Route path="hamburger-menu-half" element={<HamburgerMenuHalfPage style={{ "--base-color": "#fff" }} />} />
                  </Route>

                  {/* Footers */}
                  <Route path="footers" element={<Footer style={{ "--base-color": "#0038e3" }} />} >
                    <Route path="footer-style-01" element={<FooterStyle01Page style={{ "--base-color": "#0038e3" }} />} />
                  </Route>


                  {/* Elements */}

                  {/* Portfolios */}
                  <Route path="portfolio" element={<PortfolioPage style={{ "--base-color": "#0038e3" }} />}>
                    <Route path="portfolio-habsoe" element={<PortfolioHabsoePage style={{ "--base-color": "#fff" }} />} />
                    <Route path="portfolio-slider" element={<PortfolioSliderPage style={{ "--base-color": "#fff" }} />} />
                  </Route>

                  {/* SingleProjectPage*/}
                  {/* Blogs */}


                  {/* Blogs Types */}

                  {/* Model-Popup */}
                  {/* Icon Packs */}
                  {/* Page-Title */}
                  {/* PostLayout */}
                  {/*About Pages */}
                  {/* Services Pages */}
                  {/* Contact Pages */}
                  {/* Additional Pages */}
                  {/* Shop Pages */}
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;